<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="8" :offset="8">
                <el-card shadow="always" class="box-card">
                    <div slot="header" class="text-center">
                        <el-avatar @click.native="changeAvatar"  class="avatar"
                                   :size="80"
                                   :src="$store.state.userData.face"></el-avatar>
                    </div>
                    <div>
                        <el-form label-position="left" label-width="80px" :model="profile">
                            <el-form-item label="用户名">
                                <div>{{$store.state.userData.username}}</div>
                            </el-form-item>
                            <el-form-item label="用户组">
                                <div>{{$store.state.userData.group_id}}</div>
                            </el-form-item>
                            <el-form-item label="最后登陆">
                                <div>{{$store.state.userData.login_time}}</div>
                            </el-form-item>
                            <el-form-item label="姓名">
                                <el-input v-model="profile.nickname"></el-input>
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input show-password type="password" v-model="profile.password"></el-input>
                            </el-form-item>
                        </el-form>
                        <div style="padding-left: 80px;">
                            <el-button @click="saveProfile" type="primary" size="medium">保存</el-button>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <my-upload field="image"
                   @crop-success="cropSuccess"
                   @crop-upload-success="cropUploadSuccess"
                   @crop-upload-fail="cropUploadFail"
                   v-model="show"
                   :width="150"
                   :height="150"
                   :url="uploadUrl"
                   img-format="png"></my-upload>
    </div>
</template>
<script>
    import MyUpload from 'vue-image-crop-upload';
    export default {
        name: "profile",
        components:{
            MyUpload
        },
        data:function(){
            return {
                show:false,
                uploadUrl:process.env.VUE_APP_HOST+'/manage/api/upload',
                profile:{
                    nickname:this.$store.state.userData.nickname,
                    password:''
                }
            }
        },
        mounted() {
            console.log(this.$store.state.userData);
        },
        methods:{
            changeAvatar:function () {
                this.show=!this.show;
            },
            saveProfile:function(){
                this.$axios.put('/manage/user/profile/'+this.$store.state.userData.user_id,this.profile).then((userData)=>{
                    this.$message.success('修改成功!');
                    this.profile.password='';
                    this.$store.dispatch('userData',userData.data);
                });
            },
            cropSuccess(){
                // console.log(imgDataUrl);
            },
            cropUploadSuccess(data){
                this.profile.face=data.data.url;
                this.$store.dispatch('userData',{face:data.data.url});
            },
            cropUploadFail(){
            }
        },
    }
</script>

<style scoped>
    .avatar{
        cursor: pointer;
        user-select: none;
    }
</style>
